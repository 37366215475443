import React from "react";
import { Switch, Route } from "react-router-dom";
import Can from "Can";
import PrivateRoute from "PrivateRoute";
import SiteAdminPage from "./pages/SiteAdminPage";
import UserAdminPage from "./pages/UserAdminPage";
import TrailerAdminPage from "./pages/TrailerAdminPage";
import TrailerTypesAdminPage from "./pages/TrailerTypesAdminPage";
import SettingsLandingPage from "./pages/SettingsLandingPage";
import DeviceSimulatorPage from "./pages/DeviceSimulatorPage";
import ZonesPage from "./pages/ZonesPage";
import HauliersAdminPage from "./pages/HauliersAdminPage";
import AtlasErrorPage from "./pages/AtlasErrorPage";
import BookingsFromCsvPage from "./pages/BookingsFromCsvPage";
import DriverPinsPage from "./pages/DriverPinsPage";
import NotFoundPage from "NotFoundPage";

export default function SettingsRoot() {
  return (
    <Switch>
      <PrivateRoute exact path="/settings" component={SettingsLandingPage} />
      <PrivateRoute path="/settings/driver-pins" component={DriverPinsPage} />
      <PrivateRoute path="/settings/site-admin" component={SiteAdminPage} />
      <PrivateRoute path="/settings/user-admin" component={UserAdminPage} />
      <PrivateRoute path="/settings/zones" component={ZonesPage} />
      <PrivateRoute path="/settings/hauliers" component={HauliersAdminPage} />
      <PrivateRoute path="/settings/trailers" component={TrailerAdminPage} />
      <PrivateRoute
        path="/settings/trailer-types"
        component={TrailerTypesAdminPage}
      />
      <PrivateRoute path="/settings/atlas" component={AtlasErrorPage} />
      <PrivateRoute
        path="/settings/bookings-from-csv"
        component={BookingsFromCsvPage}
      />
      <Can when="settings.devices.simulate">
        <PrivateRoute
          path="/settings/devices/simulate"
          component={DeviceSimulatorPage}
        />
      </Can>
      <Route component={NotFoundPage} />
    </Switch>
  );
}
