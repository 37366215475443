const defaultSettings = {
  REACT_APP_CLIENT: "york",
  ALLOW_TRAILERS_EARLY_QUEUING: false, // Hams Hall (NES-1096)
  FEATURE_SSO_ENABLED: false,
  FEATURE_DOCK_SAFETY_ENABLED: false,
  FEATURE_CHECKOUT_REQUIRE_VET_CHECK: false,
  FEATURE_CHECKOUT_REQUIRE_IMAGE_CAPTURE: false,
  FEATURE_LITE_VERSION: false,
  FEATURE_TRANSPOREON_CSV: false,
  FEATURE_NO_INTERNAL_BOOKINGS: false,
  FEATURE_RETURNS_REBOOKS_UNAVAILABILITY_REASON: false,
  FEATURE_EXPRESS_CHECKIN: true
};

const settings = { ...defaultSettings, ...process.env };
export default settings;
