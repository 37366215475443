import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik, Field, Form } from "formik";
import Can from "Can";
import NumericInput from "form-controls/NumericInput";
import { getSite, updateSite } from "./site-admin-queries";
import Loader from "Loader";
import Button from "Button";
import { withErrorHandler } from "graphql-helper";
import "./SiteAdminForm.css";
import "./SiteEditForm.css";

class GatehouseConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: [],
      loading: true,
      minDayInterval: 1,
      maxDayInterval: 1,
      maxHoursTooEarly: 0,
      maxHoursTooLate: 0
    };
  }

  componentDidMount() {
    getSite(this.props.selectedSite.id).then(data => {
      this.setState({
        site: data.site,
        minDayInterval:
          data.site.siteConfig.gatehouse_config
            .show_safety_min_random_day_interval,
        maxDayInterval:
          data.site.siteConfig.gatehouse_config
            .show_safety_max_random_day_interval,
        maxHoursTooEarly:
          data.site.siteConfig.gatehouse_config.max_hours_too_early,
        maxHoursTooLate:
          data.site.siteConfig.gatehouse_config.max_hours_too_late,
        loading: false
      });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="calendar-view__loader">
          <Loader />
        </div>
      );
    }

    const init = {
      showSafetyMinRandomDayInterval: this.state.minDayInterval,
      showSafetyMaxRandomDayInterval: this.state.maxDayInterval,
      maxHoursTooEarly: this.state.maxHoursTooEarly,
      maxHoursTooLate: this.state.maxHoursTooLate
    };

    const validate = values => {
      const errors = {};

      if (!values.showSafetyMinRandomDayInterval) {
        errors.showSafetyMinRandomDayInterval = "Required";
      } else if (
        values.showSafetyMinRandomDayInterval >
        values.showSafetyMaxRandomDayInterval
      ) {
        errors.showSafetyMinRandomDayInterval =
          "Minimum random day can't be bigger or equeal Maximum random day";
      }

      if (!values.showSafetyMaxRandomDayInterval) {
        errors.showSafetyMaxRandomDayInterval = "Required";
      } else if (
        values.showSafetyMaxRandomDayInterval <=
        values.showSafetyMinRandomDayInterval
      ) {
        errors.showSafetyMaxRandomDayInterval =
          " Maximum random day can't be smaller or equal Minimum random day";
      }

      return errors;
    };
    const {
      minDayInterval,
      maxDayInterval,
      maxHoursTooEarly,
      maxHoursTooLate
    } = this.state;
    return (
      <div className="admin-main">
        <Formik
          enableReinitialize
          initialValues={init}
          validate={validate}
          validateOnChange={true}
          onSubmit={(values, { setSubmitting }) => {
            const result = {
              showSafetyMinRandomDayInterval:
                values.showSafetyMinRandomDayInterval,
              showSafetyMaxRandomDayInterval:
                values.showSafetyMaxRandomDayInterval,
              maxHoursTooEarly: values.maxHoursTooEarly,
              maxHoursTooLate: values.maxHoursTooLate
            };

            this.props
              .handleErrors(
                updateSite(this.state.site.id, result).then(() => {
                  setSubmitting(false);
                  this.props.updateSites();
                }),
                "Site successfully updated."
              )
              .then(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <h3>Random interval for reviewing safety protocol</h3>
              <Field
                name="showSafetyMinRandomDayInterval"
                label="Minimum random interval (Days) "
                component={NumericInput}
                min={1}
                max={120}
                value={minDayInterval}
                onChange={value => this.setState({ minDayInterval: value })}
                error={errors.showSafetyMinRandomDayInterval}
              />
              <Field
                name="showSafetyMaxRandomDayInterval"
                label="Maximum random interval (Days) "
                component={NumericInput}
                min={1}
                max={120}
                value={maxDayInterval}
                onChange={value => this.setState({ maxDayInterval: value })}
                error={errors.showSafetyMaxRandomDayInterval}
              />
              <h3>Refer early/late arrivals to gatehouse</h3>
              <Field
                name="maxHoursTooEarly"
                label="Maximum hours too early "
                component={NumericInput}
                min={1}
                max={48}
                value={maxHoursTooEarly}
                onChange={value => this.setState({ maxHoursTooEarly: value })}
                error={errors.maxHoursTooEarly}
              />
              <Field
                name="maxHoursTooLate"
                label="Maximum hours too late "
                component={NumericInput}
                min={0}
                max={48}
                value={maxHoursTooLate}
                onChange={value => this.setState({ maxHoursTooLate: value })}
                error={errors.maxHoursTooLate}
              />
              <div className="button-col">
                <Can when="settings.site.update">
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    title="Save"
                    disabled={isSubmitting}
                  />
                </Can>
                <Button
                  variant="secondary"
                  size="small"
                  title="Cancel"
                  onClick={() => this.props.returnHome()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withErrorHandler(connect()(withRouter(GatehouseConfigForm)));
