import React, { useState, useEffect, useCallback } from "react";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import Loader from "Loader";
import { fetch, toArgList } from "graphql-helper";
import {
  format,
  distanceInWordsToNow,
  addDays,
  isPast,
  differenceInDays
} from "date-fns";
import { parseFromTimeZone } from "date-fns-timezone";
import Button from "Button";
import Modal from "Modal";
import { Formik, Form, Field } from "formik";
import TextInput from "form-controls/TextInput";
import CheckBox from "form-controls/CheckBox";
import debounce from "lodash/debounce";

export default function DriverPinsPage() {
  const [driverPins, setDriverPins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [modals, setModals] = useState({
    pinModal: false,
    deactivateConfirmation: false
  });
  const [selectedPin, setSelectedPin] = useState(null);
  const [pinToDeactivate, setPinToDeactivate] = useState(null);

  const isClearingSearch = React.useRef(false);
  const searchInputRef = React.useRef(null);

  const debouncedLoadDriverPins = useCallback(
    debounce(async query => {
      setIsLoading(true);
      try {
        const { activeDriverPins } = await fetch(`
          query {
             activeDriverPins(searchQuery: "${query}") {
              id
              pin
              driverFirstName
              driverLastName
              agreedToGdprAt
              agreedToSafetyProtocolAt
              activatedAt
              deactivatedAt
              lastUsedAt
            }
          }
        `);
        setDriverPins(activeDriverPins);
      } catch (error) {
        console.error("Failed to load driver pins:", error);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [setIsLoading, setDriverPins]
  );

  useEffect(() => {
    addCustomStyles();
    loadData();
    setSearchText("");

    return removeCustomStyles;
  }, []);

  useEffect(() => {
    if (!isClearingSearch.current) {
      debouncedLoadDriverPins(searchText);
    } else {
      isClearingSearch.current = false;
    }
    return () => debouncedLoadDriverPins.cancel();
  }, [searchText, debouncedLoadDriverPins]);

  const handleSearchChange = e => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    isClearingSearch.current = true;
    setSearchText("");
    setIsLoading(true);
    fetch(`
      query {
         activeDriverPins(searchQuery: "") {
          id
          pin
          driverFirstName
          driverLastName
          agreedToGdprAt
          agreedToSafetyProtocolAt
          activatedAt
          deactivatedAt
          lastUsedAt
        }
      }
    `)
      .then(({ activeDriverPins }) => {
        setDriverPins(activeDriverPins);
      })
      .catch(error => {
        console.error("Failed to load driver pins:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addCustomStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .hidden-pin {
        position: relative;
        cursor: zoom-in;
      }
      .pin-reveal {
        display: none;
        position: absolute;
        left: 0;
        top: -34px;
        background-color: #fff;
        padding: 2px 4px;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        z-index: 1;
        white-space: nowrap;
      }
      .hidden-pin:hover .pin-reveal {
        display: block;
      }
      .date-container {
        position: relative;
        cursor: zoom-in;
      }
      .date-reveal {
        display: none;
        position: absolute;
        left: 0;
        top: -34px;
        background-color: #fff;
        padding: 2px 4px;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        z-index: 1;
        white-space: nowrap;
      }
      .date-container:hover .date-reveal {
        display: block;
      }
    `;
    document.head.appendChild(style);
    return style;
  };

  const removeCustomStyles = () => {
    const style = document.querySelector("style");
    if (style) document.head.removeChild(style);
  };

  const loadData = async () => {
    setIsLoading(true);
    await loadDriverPins();
  };

  const loadDriverPins = async () => {
    try {
      const { activeDriverPins } = await fetch(`
        query {
           activeDriverPins(searchQuery: "${searchText}") {
            id
            pin
            driverFirstName
            driverLastName
            agreedToGdprAt
            agreedToSafetyProtocolAt
            activatedAt
            deactivatedAt
            lastUsedAt
          }
        }
      `);
      setDriverPins(activeDriverPins);
    } catch (error) {
      console.error("Failed to load driver pins:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createDriverPin = async args => {
    await fetch(`
      mutation {
        createDriverPin(${toArgList(args)}) {
          id
        }
      }
    `);
    await loadDriverPins();
  };

  const updateDriverPin = async args => {
    await fetch(`
      mutation {
        updateDriverPin(${toArgList(args)}) {
          id
        }
      }
    `);
    await loadDriverPins();
  };

  const deactivatePin = async args => {
    await fetch(`
      mutation {
        deactivateDriverPin(id: ${args.id}) {
          id
        }
      }
    `);
    await loadDriverPins();
  };

  const openModal = (modalType, data = null) => {
    if (modalType === "pinModal") {
      setSelectedPin(data);
    } else if (modalType === "deactivateConfirmation") {
      setPinToDeactivate(data);
    }

    setModals(prev => ({
      ...prev,
      [modalType]: true
    }));
  };

  const closeModal = modalType => {
    setModals(prev => ({
      ...prev,
      [modalType]: false
    }));

    if (modalType === "pinModal") {
      setSelectedPin(null);
    } else if (modalType === "deactivateConfirmation") {
      setPinToDeactivate(null);
    }
  };

  const handleDeactivatePin = pin => {
    openModal("deactivateConfirmation", pin);
  };

  const confirmDeactivation = async () => {
    setIsLoading(true);
    await deactivatePin({ id: pinToDeactivate.id });
    closeModal("deactivateConfirmation");
    setIsLoading(false);
  };

  const handleUpdateDriverPin = pin => {
    openModal("pinModal", pin);
  };

  const renderExpiryStatus = (date, validityDays) => {
    if (!date) return "-";

    const expiryDate = addDays(new Date(date), validityDays);
    const isExpired = isPast(expiryDate);
    const daysRemaining = differenceInDays(expiryDate, new Date());

    if (isExpired) {
      const formattedExpiryDate = format(
        parseFromTimeZone(expiryDate, {
          timeZone: "Etc/UTC"
        }),
        "DD-MM-YYYY HH:mm:ss"
      );
      return (
        <span className="date-container" style={{ color: "red" }}>
          Was due {format(expiryDate, "DD-MM-YYYY")}
          <span className="date-reveal">Expired on: {formattedExpiryDate}</span>
        </span>
      );
    }

    let color = "inherit";
    if (daysRemaining <= 7) {
      color = "red";
    } else if (daysRemaining <= 30) {
      color = "orange";
    }

    return (
      <span className="date-container" style={{ color }}>
        Due in {daysRemaining} {daysRemaining === 1 ? "day" : "days"}
        <span className="date-reveal">
          Due on:{" "}
          {format(
            parseFromTimeZone(expiryDate, {
              timeZone: "Etc/UTC"
            }),
            "DD-MM-YYYY HH:mm:ss"
          )}
        </span>
      </span>
    );
  };

  if (isLoading) {
    return (
      <AppTemplate>
        <Content>
          <div className="content-block" style={{ width: "100%" }}>
            <h2>Driver PINs</h2>
            <Loader />
          </div>
        </Content>
      </AppTemplate>
    );
  }

  return (
    <div>
      {modals.pinModal && (
        <PinModal
          onClose={() => closeModal("pinModal")}
          createDriverPin={createDriverPin}
          updateDriverPin={updateDriverPin}
          setParentLoading={setIsLoading}
          selectedPin={selectedPin}
        />
      )}

      {modals.deactivateConfirmation && (
        <Modal>
          <div className="modal">
            <div className="modal__content">
              <div className="modal__header">
                <span className="modal__title">Confirm Deactivation</span>
                <i
                  className="material-icons modal__close"
                  onClick={() => closeModal("deactivateConfirmation")}
                >
                  close
                </i>
              </div>
              <div className="modal__body">
                <p>
                  Are you sure you want to deactivate the PIN for driver{" "}
                  <strong>
                    {pinToDeactivate.driverFirstName}{" "}
                    {pinToDeactivate.driverLastName}
                  </strong>
                  ?
                </p>
                <p>This action cannot be undone.</p>

                <div className="modal__buttons">
                  <Button
                    type="button"
                    variant="secondary"
                    size="tiny"
                    title="Cancel"
                    onClick={() => closeModal("deactivateConfirmation")}
                  />
                  <Button
                    type="button"
                    variant="red"
                    size="tiny"
                    title="Deactivate"
                    onClick={confirmDeactivation}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <AppTemplate>
        <Content>
          <div className="content-block" style={{ width: "100%" }}>
            <h2>Driver PINs</h2>

            {/* Search input */}
            <div className="search-container" style={{ marginBottom: "1rem" }}>
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Search by name or PIN..."
                value={searchText}
                onChange={handleSearchChange}
                autoFocus
                style={{
                  padding: "0.5rem",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  width: "300px",
                  fontSize: "14px"
                }}
              />
              {searchText && (
                <button
                  onClick={handleClearSearch}
                  style={{
                    marginLeft: "0.5rem",
                    padding: "0.5rem",
                    background: "none",
                    border: "none",
                    cursor: "pointer"
                  }}
                  aria-label="Clear search"
                >
                  <i className="material-icons" style={{ fontSize: "18px" }}>
                    clear
                  </i>
                </button>
              )}
            </div>

            {driverPins.length === 0 ? (
              <p>
                No PINs found{searchText ? ` matching "${searchText}"` : ""}
              </p>
            ) : (
              <DriverPinsTable
                driverPins={driverPins}
                onUpdate={handleUpdateDriverPin}
                onDeactivate={handleDeactivatePin}
                renderExpiryStatus={renderExpiryStatus}
              />
            )}
            <Button
              variant="secondary"
              size="small"
              title="Create new PIN"
              disabled={isLoading}
              onClick={() => openModal("pinModal")}
            />
          </div>
        </Content>
      </AppTemplate>
    </div>
  );
}

function DriverPinsTable({
  driverPins,
  onUpdate,
  onDeactivate,
  renderExpiryStatus
}) {
  return (
    <table>
      <thead>
        <tr>
          <th>PIN</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Status</th>
          <th>GDPR</th>
          <th>Safety Protocol</th>
          <th>Last activity</th>
          <th style={{ textAlign: "center" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {driverPins.map(pin => (
          <tr key={pin.pin}>
            <td>
              <span className="hidden-pin" title="Hover to reveal PIN">
                {pin.pin.replace(/./g, "•")}
                <span className="pin-reveal">{pin.pin}</span>
              </span>
            </td>
            <td>{pin.driverFirstName}</td>
            <td>{pin.driverLastName}</td>
            <td>
              {pin.deactivatedAt
                ? "Deactivated"
                : pin.activatedAt
                ? "Active"
                : "Pending"}
            </td>
            <td>{renderExpiryStatus(pin.agreedToGdprAt, 365)}</td>
            <td>{renderExpiryStatus(pin.agreedToSafetyProtocolAt, 365)}</td>
            <td>
              {pin.lastUsedAt
                ? distanceInWordsToNow(pin.lastUsedAt, {
                    addSuffix: true
                  })
                : "-"}
            </td>
            <td>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <Button
                  variant="primary"
                  size="tiny"
                  title="Update"
                  onClick={() => onUpdate(pin)}
                />
                <Button
                  variant="red"
                  size="tiny"
                  title="Deactivate"
                  onClick={() => onDeactivate(pin)}
                  disabled={pin.deactivatedAt !== null}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function PinModal({
  onClose,
  setParentLoading,
  createDriverPin,
  updateDriverPin,
  selectedPin
}) {
  const [isLoading, setIsLoading] = useState(false);
  const isEditMode = !!selectedPin;

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Modal>
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <span className="modal__title">
              {isEditMode
                ? "Update PIN for driver"
                : "Create new PIN for driver"}
            </span>
            <i className="material-icons modal__close" onClick={onClose}>
              close
            </i>
          </div>
          <div className="modal__body">
            {isLoading && <Loader />}

            {!isLoading && (
              <Formik
                enableReinitialize
                initialValues={{
                  driverFirstName: selectedPin
                    ? selectedPin.driverFirstName
                    : "",
                  driverLastName: selectedPin ? selectedPin.driverLastName : "",
                  hasAgreedToGdpr: false,
                  hasAgreedToSafetyProtocol: false,
                  ...(selectedPin && { id: selectedPin.id })
                }}
                onSubmit={values => {
                  setParentLoading(true);

                  const promise = isEditMode
                    ? updateDriverPin(values)
                    : createDriverPin(values);

                  promise.then(() => {
                    setParentLoading(false);
                    onClose();
                  });
                }}
              >
                {({ values }) => (
                  <Form>
                    <div>
                      <Field
                        name="driverFirstName"
                        label="First name"
                        component={TextInput}
                        disabled={isEditMode}
                      />
                      <Field
                        name="driverLastName"
                        label="Last name"
                        component={TextInput}
                        disabled={isEditMode}
                      />
                      <div style={{ marginLeft: "-1rem" }}>
                        <Field
                          name="hasAgreedToGdpr"
                          label="Agreed to GDPR?"
                          component={CheckBox}
                        />
                        <Field
                          name="hasAgreedToSafetyProtocol"
                          label="Agreed to Safety Protocol?"
                          component={CheckBox}
                        />
                      </div>
                    </div>
                    <div className="modal__buttons">
                      <Button
                        type="button"
                        variant="secondary"
                        size="tiny"
                        title="Cancel"
                        onClick={onClose}
                      />
                      <Button
                        type="submit"
                        variant="primary"
                        size="tiny"
                        title={isEditMode ? "Update" : "Create"}
                        disabled={
                          (!isEditMode &&
                            (!values.driverFirstName ||
                              !values.driverLastName)) ||
                          !values.hasAgreedToGdpr ||
                          !values.hasAgreedToSafetyProtocol
                        }
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
